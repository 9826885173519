<template src="./home.html"></template>

<script>
import CustomCard from "@/components/content/home/card.vue";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/service/firebase.js";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
    CustomCard,
  },
  data() {
    return {
      loading: true,
      totalComprasConcluidas: 0,
      totalCompras: 0,
      totalImpressoesPolaroid: 0,
      totalImpressoesNormal: 0,
      totalImpressoes3x4: 0,
      totalValorCartaoCredito: 0,
      totalValorCartaoDebito: 0,
      totalValorPix: 0,
      valorTotal: 0,
      compras: [],
      customizarTempo: false,
      periodos: [
        { id: 0, descricao: "Hoje" },
        { id: 1, descricao: "Últimos 7 dias" },
        { id: 2, descricao: "Últimos 30 dias" },
        { id: 3, descricao: "Esse ano" },
      ],
      totens: [],
      categoriesWeekDays: [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo",
      ],
      categories: [
        "0hr ás 2hr",
        "3hr ás 5hr",
        "6hr ás 8hr",
        "9hr ás 11hr",
        "12hr ás 14hr",
        "15hr ás 17hr",
        "18hr ás 20hr",
        "21hr ás 23hr",
      ],
      filtros: {
        dataInicio: null,
        dataFim: null,
        tpPeriodo: 0,
        totemId: 0,
      },
      series: [
        {
          name: "Compra iniciada",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Compra concluída",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      seriesWeekDays: [
        {
          name: "Compra iniciada",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Compra concluída",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: "auto",
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Vendas por hora",
        },
        xaxis: {
          tickPlacement: "on",
          type: "text",
          categories: [],
          labels: {
            trim: true,
            show: false,
            formatter: function (val) {
              return parseInt(val) + " qtd";
            },
          },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  },
  mounted() {
    this.getData();

    this.filtros.dataInicio = new Date().toISOString().split("T")[0];
    this.filtros.dataFim = new Date().toISOString().split("T")[0];

    this.chartOptions.xaxis.categories = this.categories;
  },
  filters: {
    realValue(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  computed: {
    getComprasConcluidas() {
      return this.compras.filter((compra) => compra.etapa == "AGRADECIMENTO")
        .length;
    },
    getComprasCanceladas() {
      return this.compras.filter((compra) => compra.etapa !== "AGRADECIMENTO")
        .length;
    },
    getValorTotal() {
      let lista = this.compras.filter(
        (compra) => compra.etapa == "AGRADECIMENTO"
      );
      let valorTotal = lista.reduce((total, compra) => {
        return total + compra.valor;
      }, 0);
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(valorTotal);
    },
  },
  methods: {
    getIdentifyRow(hora) {
      let identifyRow = 0;
      if (hora >= 0 && hora <= 2) identifyRow = 0;
      if (hora >= 3 && hora <= 5) identifyRow = 1;
      if (hora >= 6 && hora <= 8) identifyRow = 2;
      if (hora >= 9 && hora <= 11) identifyRow = 3;
      if (hora >= 12 && hora <= 14) identifyRow = 4;
      if (hora >= 15 && hora <= 17) identifyRow = 5;
      if (hora >= 18 && hora <= 20) identifyRow = 6;
      if (hora >= 21 && hora <= 23) identifyRow = 7;
      return identifyRow;
    },
    contagemImpressao(tipo, quantidade) {
      if (tipo === "polaroid") {
        this.totalImpressoesPolaroid += quantidade / 2;
      } else if (tipo == "normal") {
        this.totalImpressoesNormal += quantidade;
      } else if (tipo == "fotos_3x4") {
        this.totalImpressoes3x4 += quantidade;
      } else {
        console.error("Tipo não definido", tipo);
      }
    },
    contagemPagamento(compra) {
      if (!compra.tipoCartao) {
        // caso de dados antigos é desconsiderado;
      } else {
        if (compra.tipoCartao == "Debito") {
          this.totalValorCartaoDebito += compra.valor;
        } else if (compra.tipoCartao == "PIX") {
          this.totalValorPix += compra.valor;
        } else if (compra.tipoCartao == "Credito") {
          this.totalValorCartaoCredito += compra.valor;
        } else {
          //
          console.error("TipoCartão não definido na contagem de pagamento");
        }
      }
    },
    onChangeFiltro() {
      this.loading = true;
      if (this.filtros.tpPeriodo == 0 && this.customizarTempo == false) {
        this.chartOptions.xaxis.categories = this.categories;
      } else {
        this.chartOptions.xaxis.categories = this.categoriesWeekDays;
      }
      this.seriesWeekDays[1].data = [0, 0, 0, 0, 0, 0, 0];
      this.seriesWeekDays[0].data = [0, 0, 0, 0, 0, 0, 0];
      this.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0];
      this.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0];
      this.compras = [];
      this.totalImpressoesPolaroid = 0;
      this.totalImpressoesNormal = 0;
      this.totalImpressoes3x4 = 0;
      this.totalValorCartaoCredito = 0;
      this.totalValorCartaoDebito = 0;
      this.totalValorPix = 0;

      this.getComprasPorTotemID(this.filtros.totemId);
      setTimeout(() => {
        this.timelineGenerate();
      }, 2000);
    },
    timelineGenerate() {
      this.compras.forEach((compra) => {
        let identifyRow;

        if (this.filtros.tpPeriodo == 0 && !this.customizarTempo) {
          let hora = compra.keep_alive.toDate().getHours();
          identifyRow = this.getIdentifyRow(hora);
          if (compra.etapa !== "AGRADECIMENTO") {
            let somar = this.series[0].data[identifyRow] + 1;
            this.series[0].data[identifyRow] = somar;
          }
          if (compra.etapa === "AGRADECIMENTO") {
            this.contagemImpressao(compra.tipo_foto, compra.qtde_foto);
            this.contagemPagamento(compra);
            let somar = this.series[1].data[identifyRow] + 1;
            this.series[1].data[identifyRow] = somar;
          }
        } else {
          let day = compra.keep_alive.toDate().getDay();
          identifyRow = day;
          if (compra.etapa !== "AGRADECIMENTO") {
            let somar = this.seriesWeekDays[0].data[identifyRow] + 1;
            this.seriesWeekDays[0].data[identifyRow] = somar;
          }
          if (compra.etapa === "AGRADECIMENTO") {
            this.contagemImpressao(compra.tipo_foto, compra.qtde_foto);
            this.contagemPagamento(compra);
            let somar = this.seriesWeekDays[1].data[identifyRow] + 1;
            this.seriesWeekDays[1].data[identifyRow] = somar;
          }
        }
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    getDataTime() {
      let dateBase = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      );

      let dataInicioFiltro = new Date(this.filtros.dataInicio);
      let dataFimFiltro = new Date(this.filtros.dataFim);

      dataInicioFiltro.setDate(dataInicioFiltro.getDate() + 1);
      dataFimFiltro.setDate(dataFimFiltro.getDate() + 1);

      dataFimFiltro.setHours(23);
      dataFimFiltro.setMinutes(59);
      dataFimFiltro.setSeconds(59);
      dataInicioFiltro.setHours(0);
      dataInicioFiltro.setMinutes(0);
      dataInicioFiltro.setSeconds(0);

      let lastWeek = new Date(new Date().setDate(dateBase.getDate() - 7));
      let lastMonth = new Date(new Date().setDate(dateBase.getDate() - 30));
      let lastYear = new Date(new Date().setDate(dateBase.getDate() - 365));

      let startDay = dateBase;
      let endDay = new Date(
        dateBase.getFullYear(),
        dateBase.getMonth(),
        dateBase.getDate(),
        23,
        59,
        59
      );
      return {
        startDay,
        endDay,
        lastWeek,
        lastMonth,
        lastYear,
        dataInicioFiltro,
        dataFimFiltro,
      };
    },
    updateCompras(id) {
      let compraRef = doc(db, "compras", id);
      setDoc(compraRef, { pago: true }, { merge: true });
    },
    async updateBanco() {
      this.compras.forEach((compra) => {
        console.log(compra);
        this.updateCompras(compra.id);
      });
      //const querySnapshot = await getDocs(collection(db, "compras"));
      //querySnapshot.forEach((doc) => {
      //  this.updateCompras(doc.id);
      //});
    },
    async getComprasPorTotemID(id) {
      const citiesRef = collection(db, "compras");
      let q;
      if (this.customizarTempo) {
        const { dataInicioFiltro, dataFimFiltro } = this.getDataTime();
        q = query(
          citiesRef,
          where("totem_id", "==", id),
          where("keep_alive", ">=", dataInicioFiltro),
          where("keep_alive", "<=", dataFimFiltro),
          where("pago", "==", true)
        );
      } else if (this.filtros.tpPeriodo == 0) {
        const { startDay, endDay } = this.getDataTime();
        q = query(
          citiesRef,
          where("totem_id", "==", id),
          where("keep_alive", ">=", startDay),
          where("keep_alive", "<=", endDay),
          where("pago", "==", true)
        );
      } else if (this.filtros.tpPeriodo == 1) {
        const { lastWeek, endDay } = this.getDataTime();
        q = query(
          citiesRef,
          where("totem_id", "==", id),
          where("keep_alive", ">=", lastWeek),
          where("keep_alive", "<=", endDay),
          where("pago", "==", true)
        );
      } else if (this.filtros.tpPeriodo == 2) {
        const { lastMonth, endDay } = this.getDataTime();
        q = query(
          citiesRef,
          where("totem_id", "==", id),
          where("keep_alive", ">=", lastMonth),
          where("keep_alive", "<=", endDay),
          where("pago", "==", true)
        );
      } else if (this.filtros.tpPeriodo == 3) {
        const { lastYear, endDay } = this.getDataTime();
        q = query(
          citiesRef,
          where("totem_id", "==", id),
          where("keep_alive", ">=", lastYear),
          where("keep_alive", "<=", endDay),
          where("pago", "==", true)
        );
      }
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((snap) => {
        this.compras.push({ ...snap.data(), id: snap.id });
      });
    },
    getComprasForTotens() {
      const totems = this.$store.getters["dashboard/getTotens"];
      this.totens = totems;
      if (this.totens != null) {
        this.filtros.totemId = this.totens[0].id;
      }
      this.getComprasPorTotemID(this.filtros.totemId);

      setTimeout(() => {
        this.timelineGenerate();
      }, 2000);
    },
    getData() {
      this.getComprasForTotens();
    },
  },
};
</script>

<style></style>
